<template>
  <div>
    <div class="text-sm" v-if="serviceRequest && formValues">
      <div class="flex justify-between mb-5" v-if="serviceRequest.reporterResidentProfile">
        <div>
          {{toFullName(serviceRequest.reporterResidentProfile.resident)}}
        </div>
        <div>
          {{unitName}}
        </div>
      </div>
      <FinalForm
        :submit="handleFormSubmit"
        ref="form"
        :initial-values="serviceRequest"
        @change.self="handleFormChange"
      >
        <template v-slot="props">
          <form @submit="props.handleSubmit">
            <div class="main-section" :class="mainSectionClasses">
              <div class="flex justify-between mb-4">
                <div class="heading">
                  main information
                  <span v-if="isOnResidentBehalf">(on resident behalf)</span>
                  <span v-if="isNotOnResidentBehalf">(not on resident behalf)</span>
                </div>
                <div class="flex" v-if="editable && !isTerminal && fastStatuses.length === 0">
                  <button type="button" class="btn-primary btn-small" @click="props.handleSubmit">save</button>
                </div>
              </div>
              <div v-if="!formValues.hideDeclinationByResidentMessage && formValues.declinationByResidentCount > 0" class="text-red-900 mb-5">
                <icon name="alert2" class="w-4 h-4 mr-1 inline-block"/>
                <span class="text-xs font-semibold">declined by resident</span>
              </div>
              <div v-if="!formValues.hideDeclinationByPmMessage && formValues.declinationByPmCount > 0" class="text-red-900 mb-5">
                <icon name="alert2" class="w-4 h-4 mr-1 inline-block"/>
                <span class="text-xs font-semibold">declined by property manager</span>
              </div>
              <div class="grid grid-cols-4 mr-8">
                <SelectInput
                  :edit-mode="editable && !isTerminal && hasSreqPermission('SRSU')"
                  label="status*"
                  name="currentState"
                  :options="statuses"
                  :validate="required"
                  :object-mode="true"
                  :disabled="fastStatuses.length > 0"
                >
                  <template v-slot:option="{option}">
                    <div class="flex items-center min-w-0">
                      <div class="w-5 flex-shrink-0">
                        <icon v-if="option.key === 'COMPLETED'" name="completed"/>
                        <icon v-if="option.key === 'CANCELED'" name="canceled"/>
                      </div>
                      <span class="truncate">{{option.value}}</span>
                    </div>
                  </template>
                </SelectInput>
                <div class="form-col">
                  <label>{{savedServiceRequest.currentState.value}} by</label>
                  <div>{{stateModifierName}}</div>
                  <DateInput :edit-mode="false" name="stateChangedAt"/>
                </div>
                <template v-if="savedServiceRequest.statusSchema === 'ADVANCED'">
                  <SelectInput
                      :edit-mode="editable && !isTerminal && hasSreqPermission('SRAU')"
                      :label="label('assigned to', formValues, 'modificationRules.assignee', 'REQUIRED')"
                      name="assignee.id"
                      :options="assignees"
                      :disabled="isDisabled(formValues,'assignee')"
                      :validate="triggerValidator('modificationRules.assignee', 'REQUIRED', required)"
                  />
                  <TextField
                      :edit-mode="editable && !isTerminal"
                      :label="label('scheduled time', formValues, 'modificationRules.schedule', 'REQUIRED')"
                      name="schedule"
                      :disabled="isDisabled(formValues,'schedule')"
                      :validate="composeValidators(
                        triggerValidator(
                          'modificationRules.schedule',
                          'REQUIRED',
                          composeValidators(minLength(2), maxLength(50), required)
                        ),
                        triggerValidator(
                          'modificationRules.schedule',
                          'OPTIONAL',
                          composeValidators(minLength(2), maxLength(50))
                        ),
                      )"
                  />
                </template>
              </div>
              <div v-if="fastStatuses.length > 0" class="fast-actions flex flex-wrap justify-end mt-4">
                <button
                  v-for="status in fastStatuses"
                  :key="status.id"
                  type="button"
                  class="btn-primary"
                  :class="{'btn-solid': status.fastAction === 'ACCEPT'}"
                  @click="handleFastStatus(status)"
                >
                  <span v-if="status.fastAction === 'DECLINE'">
                    decline & turn into {{status.value}}
                  </span>
                  <span v-if="status.fastAction === 'ACCEPT'">
                    accept
                  </span>
                </button>
              </div>
              <div v-if="serviceRequest.statusSchemaDifferent" class="text-xs text-gray-500 mt-6">
                The request had been created before the status scheme changed.<br>
                The request status logic sticks to the previous status scheme.
              </div>
            </div>

            <div class="section">
              <div class="flex justify-between mb-4">
                <div class="heading">problem location</div>
                <EditIconsBlock
                    v-if="editable"
                    :edit-mode="editLocation"
                    @startEdit="beginEditLocation"
                    @cancel="cancelEdit"
                    @save="props.handleSubmit"
                />
              </div>
              <div class="locations" v-if="editLocation">
                <template v-for="(id, index) in formValues.locationPath.length + 1" :key="index">
                  <div
                      v-if="index > 0"
                      :class="locationSelectClasses(formValues.locationPath, formValues.areaType, index) || 'arrow-wrapper'"
                  >
                    <icon name="arrowRight2" class="flex-shrink-0"/>
                  </div>
                  <SelectInput
                    :disabled="isLocationDisabled(index)"
                    :class="locationSelectClasses(formValues.locationPath, formValues.areaType, index)"
                    :name="`locationPath.${index}`"
                    :label="locationDropdownLabel(formValues.areaType, index)"
                    :options="locationDropdownOptions(formValues.locationPath, formValues.areaType, index)"
                    :validate="locationValidation(formValues.areaType, index)"
                  />
                </template>
                <template v-if="isCustomLocation(formValues.locationPath)">
                  <div class="arrow-wrapper">
                    <icon name="arrowRight2" class="flex-shrink-0"/>
                  </div>
                  <TextField
                      name="customLocation"
                      label="other location*"
                      :validate="required"
                  />
                </template>
              </div>
              <div v-if="!editLocation">
                {{initialLocationString}}
              </div>
              <div class="grid grid-cols-3 mt-4 mr-8">
                <CheckboxInput
                  v-if="!formValues.sharedLocation"
                  :edit-mode="editLocation"
                  :disabled="isDisabled(formValues, 'enterPermissionGranted')"
                  label="enter permission"
                  name="enterPermissionGranted"
                  value="granted"
                />
              </div>
            </div>

            <div class="section request-details">
              <div class="flex justify-between mb-4">
                <div class="heading">request details</div>
                <EditIconsBlock
                    v-if="editable"
                    :edit-mode="editDetails"
                    @startEdit="beginEditDetails"
                    @cancel="cancelEdit"
                    @save="props.handleSubmit"
                />
              </div>
              <div class="grid grid-cols-4 mb-8 mr-8">
                <SelectInput
                  :edit-mode="editDetails"
                  :label="label('priority', formValues, 'modificationRules.priority', 'REQUIRED')"
                  name="priority.id"
                  :options="priorities"
                  :disabled="isDisabled(formValues,'priority')"
                  :validate="triggerValidator('modificationRules.priority', 'REQUIRED', required)"
                >
                  <template v-slot:option="{option}">
                    <template v-if="option">
                      <PriorityIndicator :value="option.level" class="mr-2 flex-shrink-0"/>
                      <span class="truncate">{{option.value}}</span>
                    </template>
                  </template>
                </SelectInput>
                <template v-for="(id, index) in formValues.categoryPath.length + 1" :key="index">
                  <SelectInput
                    :edit-mode="editDetails"
                    :class="categorySelectClasses(formValues.categoryPath, editDetails, index)"
                    :name="`categoryPath.${index}`"
                    :label="categoryDropdownLabel(index)"
                    :options="categoryDropdownOptions(formValues.categoryPath, index)"
                    :disabled="isDisabled(formValues,'category')"
                    :validate="categoryValidation(index, triggerValidator('modificationRules.category', 'REQUIRED', required))"
                  />
                </template>
                <TextField
                  :edit-mode="editDetails"
                  v-if="isCustomCategory(formValues.categoryPath)"
                  name="customCategory"
                  :label="categoryOtherLabel(formValues.categoryPath)"
                  :disabled="isDisabled(formValues,'category')"
                  :validate="composeValidators(required, minLength(2), maxLength(40))"
                />
              </div>
              <div class="grid grid-cols-4 mr-8">
                <TextField
                  v-if="!serviceRequest.sharedLocation"
                  class="alarm-code-input"
                  name="alarmCode"
                  label="alarm code"
                  :edit-mode="editDetails"
                  :disabled="isDisabled(formValues,'alarmCode')"
                  :validate="maxLength(50)"
                />
                <div class="form-col">
                  <label>creation date <Tooltip icon="clock" position-x="right" text="All dates and times are displayed according to the Community time zone."/></label>
                  <DateInput :edit-mode="false" name="createdAt"/>
                  <div>{{reporterName}}</div>
                </div>
                <TextField class="col-span-2" :edit-mode="false" label="description" name="description" multiline/>
              </div>
              <AttachmentsField
                class="mt-8 mr-8"
                with-images
                :items="serviceRequest.attachments"
                service="SREQ"
                :max-items="20"
                :accept="[
                  'image/jpeg',
                  'image/png',
                ]"
                :readonly="!editDetails || isDisabled(formValues,'attachments') || !hasSreqPermission('SRAttU')"
              />
            </div>

            <div class="section" v-if="formValues.modificationRules.notificationPreferences !== 'NULL_ONLY'">
              <div class="flex justify-between mb-4">
                <div class="heading">appointment</div>
                <EditIconsBlock
                    v-if="!isDisabled(formValues, 'notificationPreferences') && editable"
                    :edit-mode="editAppointment"
                    @startEdit="beginEditAppointment"
                    @cancel="cancelEdit"
                    @save="props.handleSubmit"
                />
              </div>
              <div class="grid grid-cols-3 mr-8">
                <div class="form-col">
                  <label>preferred contact method</label>
                  <template v-if="editAppointment">
                    <div class="flex mb-4">
                      <RadioInput
                        class="contact-channel"
                        name="contactPreferences.preferredChannel"
                        label="email"
                        value="EMAIL"
                      />
                      <TextField
                        name="contactPreferences.email"
                        label=""
                        :disabled="!isPreferredChannel(formValues, 'EMAIL')"
                        :validate="triggerValidator('contactPreferences.preferredChannel', 'EMAIL', composeValidators(required, email))"
                      />
                    </div>
                    <div class="flex">
                      <RadioInput
                        class="contact-channel"
                        name="contactPreferences.preferredChannel"
                        label="text"
                        value="PHONE"
                      />
                      <PhoneInput
                        name="contactPreferences.phone"
                        label=""
                        :disabled="!isPreferredChannel(formValues, 'PHONE')"
                        :validate="triggerValidator('contactPreferences.preferredChannel', 'PHONE', composeValidators(required, phoneNumber))"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <div v-if="isPreferredChannel(formValues, 'EMAIL')">
                      {{formValues.contactPreferences.email}}
                    </div>
                    <div v-else-if="isPreferredChannel(formValues, 'PHONE')">
                      <PhoneInput
                        name="contactPreferences.phone"
                        label=""
                        :edit-mode="false"
                      />
                    </div>
                    <div v-else>-</div>
                  </template>
                </div>
                <div class="form-col">
                  <label>
                    additional notification method
                    <Tooltip
                        icon="info"
                        position-x="right"
                        position-y="top"
                        text="Resident will see all the notifications in the Resident portal. Additionally we’ll send the notifications to:"
                    />
                  </label>
                  <template v-if="editAppointment">
                    <div class="flex mb-4">
                      <CheckboxInput name="notificationPreferences.sendEmail" value="email" class="contact-channel mt-2"/>
                      <TextField
                        name="notificationPreferences.email"
                        label=""
                        :disabled="!formValues.notificationPreferences.sendEmail"
                        :validate="triggerValidator('notificationPreferences.sendEmail', true, composeValidators(required, email))"
                      />
                    </div>
                    <div class="flex">
                      <CheckboxInput name="notificationPreferences.sendText" value="text" class="contact-channel mt-2"/>
                      <PhoneInput
                        name="notificationPreferences.phone"
                        label=""
                        :disabled="!formValues.notificationPreferences.sendText"
                        :validate="triggerValidator('notificationPreferences.sendText', true, composeValidators(required, phoneNumber))"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <div v-if="formValues.notificationPreferences.sendEmail">
                      {{formValues.notificationPreferences.email}}
                    </div>
                    <div v-if="formValues.notificationPreferences.sendText">
                      <PhoneInput
                        name="notificationPreferences.phone"
                        label=""
                        :edit-mode="false"
                      />
                    </div>
                    <div v-if="!formValues.notificationPreferences.sendEmail && !formValues.notificationPreferences.sendText">-</div>
                  </template>
                </div>
                <div class="form-col">
                  <label>notifications</label>
                  <CheckboxInput :edit-mode="editAppointment" name="notificationPreferences.sendOnStatusChange" value="status change"/>
                  <CheckboxInput :edit-mode="editAppointment" name="notificationPreferences.sendOnNewChatMessage" value="new chat message"/>
                  <CheckboxInput :edit-mode="editAppointment" name="notificationPreferences.sendOnNewNote" value="new note"/>
                </div>
              </div>
            </div>

            <div class="section">
              <div class="heading mb-2">time-in/time-out log</div>

              <div class="flex items-center text-active-800 mb-4">
                <exclamation-circle-icon class="w-3 h-3 mr-1"/>
                <span class="font-frank font-medium text-xs">after saving you won’t be able to edit or delete it</span>
              </div>

              <TimeInOutLog v-if="hasSreqPermission('TLV')" :log-items="timeInOutLogs"/>
            </div>
          </form>
        </template>
      </FinalForm>
    </div>
    <loader :loading="loading" :backdrop="true" />
  </div>
</template>

<script>
  import {FinalForm} from 'vue-final-form';
  import NotifyMixin from '@/mixins/NotifyMixin';
  import ModalNavigation from "@/mixins/ModalNavigation";
  import TextField from '@/components/form/TextField';
  import SelectInput from '@/components/form/SelectInput';
  import DateInput from "@/components/form/DateInput";
  import PhoneInput from "@/components/form/PhoneInput";
  import Icon from "@/components/ui/Icon";
  import Loader from '@/components/ui/Loader'
  import Tooltip from "@/components/ui/Tooltip";
  import {
    AREA_UNIT,
    AREA_COMMON,
    STATUS_CANCELED,
    STATUS_COMPLETED,
    LOCATION_CUSTOM, CATEGORY_CUSTOM,
  } from './constants';
  import LocationMixin from './LocationMixin';
  import CheckboxInput from '@/components/form/CheckboxInput';
  import PriorityIndicator from "@/components/sreq/PriorityIndicator";
  import ValidatorMixin from '@/components/form/ValidatorMixin';
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import RadioInput from '@/components/form/RadioInput';
  import EventBus from "@/utils/EventBus";
  import {mapActions} from "vuex";
  import {cloneDeep, get, isEqual} from 'lodash-es';
  import {joinItems} from "@/utils/Array";
  import SreqMixin from "@/mixins/SreqMixin";
  import CategoryMixin from "@/views/sreq/requests/CategoryMixin";
  import AttachmentsField from "@/components/ui/AttachmentsField";
  import AttachmentMixin from "@/mixins/AttachmentMixin";
  import ExclamationCircleIcon from "@/components/ui/icons/ExclamationCircleIcon";
  import TimeInOutLog from "@/components/sreq/TimeInOutLog";
  import EditIconsBlock from "@/components/ui/EditIconsBlock";

  export default {
    components: {
      TimeInOutLog,
      ExclamationCircleIcon,
      EditIconsBlock,
      RadioInput,
      PriorityIndicator,
      CheckboxInput,
      Tooltip,
      DateInput,
      SelectInput,
      TextField,
      PhoneInput,
      FinalForm,
      Icon,
      Loader,
      AttachmentsField,
    },

    mixins: [
      SreqMixin,
      NotifyMixin,
      ValidatorMixin,
      ModalNavigation,
      LocationMixin,
      CategoryMixin,
      ConfirmationMixin,
      SreqMixin,
      AttachmentMixin,
    ],

    data() {
      return {
        loading: false,
        editLocation: false,
        editDetails: false,
        editAppointment: false,
        priorities: [],
        statuses: [],
        assignees: [],
        serviceRequest: null,
        savedServiceRequest: null,
        resetFields: [],
        formValues: null,
        initialLocationString: '',
        timeInOutLogs: [],
      };
    },

    computed: {
      id() {
        return this.$route.params.requestId;
      },

      isOnResidentBehalf() {
        return this.serviceRequest.reporterStaffUser
          && this.serviceRequest.residentSpecifiedOnCreate;
      },

      isNotOnResidentBehalf() {
        return this.serviceRequest.reporterStaffUser
          && !this.serviceRequest.residentSpecifiedOnCreate;
      },

      editable() {
        return this.hasSreqPermission('SRU');
      },

      isTerminal() {
        const status = this.serviceRequest.currentState.key;

        return status === STATUS_COMPLETED || status === STATUS_CANCELED;
      },

      reporterName() {
        const reporter = this.serviceRequest.reporterStaffUser || this.serviceRequest.reporterResidentProfile?.resident;
        return this.toFullName(reporter);
      },

      stateModifierName() {
        const {stateModifier} = this.serviceRequest;

        return stateModifier
          ? this.toFullName(stateModifier)
          : 'system';
      },

      unitName() {
        if (this.serviceRequest.sharedLocation) {
          return null;
        }

        const building = this.serviceRequest.location;
        const unit = building.locations[0];

        return `${building.name}:${unit.name}`.toLowerCase();
      },

      mainSectionClasses() {
        switch (this.serviceRequest.currentState.key) {
          case STATUS_CANCELED:
            return {canceled: true};

          case STATUS_COMPLETED:
            return {completed: true};

          default:
            return {};
        }
      },

      fastStatuses() {
        return this.statuses.filter(s => s.fastAction);
      },
    },

    methods: {
      ...mapActions({
        addAction: 'actions/addAction',
        resetActions: 'actions/resetActions',
        resetTmpBuffer: 'sreq/resetTmpBuffer',
      }),

      handleFormChange(state) {
        this.formValues = state.values;
      },

      beginEditLocation() {
        this.cancelEdit();

        this.loading = true;
        this.loadAllLocations()
          .then(() => {
            this.editLocation = true;
          })
          .catch(e => {
            this.notifyError(e.message);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      beginEditDetails() {
        this.cancelEdit();
        this.editDetails = true;
      },

      beginEditAppointment() {
        this.cancelEdit();
        this.editAppointment = true;
      },

      cancelEdit() {
        this.endEdit();
        this.serviceRequest = cloneDeep(this.savedServiceRequest);
        this.$refs.form.finalForm.reset(this.serviceRequest);
      },

      endEdit() {
        this.resetFields = [];
        // this.editMain = false;
        this.editLocation = false;
        this.editDetails = false;
        this.editAppointment = false;
      },

      label(label, values, triggerField, triggerValue) {
        const star = get(values, triggerField) === triggerValue ? '*' : '';
        return label + star;
      },

      isDisabled(values, field) {
        const rule = values.modificationRules[field];
        return rule === 'NULL_ONLY' || rule === 'READ_ONLY';
      },

      isPreferredChannel(values, channel) {
        return values.contactPreferences?.preferredChannel === channel;
      },

      toFullName(person) {
        return `${person.firstName} ${person.lastName}`.toLowerCase();
      },

      async loadAllLocations() {
        for (const [index, locationId] of this.formValues.locationPath.entries()) {
          if (this.isLocationDisabled(index + 1)) {
            continue;
          }

          await this.loadLocations(locationId);
        }
      },

      loadLocations(parentId) {
        const areaType = this.serviceRequest.areaType;

        const residentProfileId = areaType === AREA_UNIT
          ? this.serviceRequest.reporterResidentProfile?.id
          : null;

        return this.loadLocationsData(areaType, parentId, residentProfileId)
          .catch(error => this.notifyError(error.message));
      },

      loadCategories(parentId) {
        return this.loadCategoriesData(parentId)
          .catch(error => this.notifyError(error.message));
      },

      mapItemToOption(item) {
        return {
          key: item.id,
          value: item.name.toLowerCase(),
        };
      },

      isLocationDisabled(index) {
        return this.formValues.areaType === AREA_UNIT
          ? index < 2
          : index < 1;
      },

      locationSelectClasses(locationPath, areaType, index) {
        if (!this.editLocation && (!locationPath[index] || locationPath[index] === LOCATION_CUSTOM)) {
          return {hidden: true};
        }

        if (this.locationDropdownOptions(locationPath, areaType, index).length === 0) {
          return {hidden: true};
        }
      },

      locationDropdownLabel(areaType, index) {
        if (areaType === AREA_UNIT) {
          switch (index) {
            case 0:
              return 'building';

            case 1:
              return 'unit';
          }
        }

        return `location (level ${index + 1})${index === 0 ? '*' : ''}`;
      },

      buildLocationPath(path, locationBranch, customLocation) {
        if (!locationBranch) {
          return customLocation
            ? [...path, LOCATION_CUSTOM]
            : path;
        }

        return this.buildLocationPath(
          [
            ...path,
            locationBranch.id,
          ],
          locationBranch.locations?.[0],
          customLocation,
        );
      },

      buildCategoryPath(path, categoryBranch, customCategory) {
        if (!categoryBranch) {
          return customCategory
            ? [...path, CATEGORY_CUSTOM]
            : path;
        }

        return this.buildCategoryPath(
          [
            categoryBranch.id,
            ...path,
          ],
          categoryBranch.parent,
          customCategory,
        );
      },

      async initializeServiceRequest(serviceRequest) {
        const requestData = await this.getRequestData(serviceRequest);
        this.serviceRequest = this.formValues = requestData;
        this.savedServiceRequest = cloneDeep(requestData);
        this.resetFields = [];

        this.setActiveModalTitle(serviceRequest.data.title);
        this.setActiveModalBreadcrumbName(serviceRequest.data.title);

        // For LocationMixin
        this.areaTypeProxy = this.serviceRequest.areaType;
        this.residentProfileIdProxy = this.serviceRequest.areaType === AREA_UNIT
            ? this.serviceRequest.reporterResidentProfile?.id
            : null;
        this.insertResidentLocations([serviceRequest.data.location]);
        this.initialLocationString = this.locationToString(this.formValues.areaType, this.formValues.locationPath, this.formValues.customLocation);

        this.categories = this.collectCategoryTree(serviceRequest.metadata.category, this.categories);

        this.statuses = (serviceRequest.modificationRules.currentState ?? [{state: serviceRequest.metadata.currentState}])
          .map(item => ({
            key: item.state.id,
            value: item.state.name.toLowerCase(),
            fastAction: item.fastAction,
            modificationRules: {
              assignee: serviceRequest.metadata.statusSchema === 'ADVANCED' && item.assignee,
              schedule: serviceRequest.metadata.statusSchema === 'ADVANCED' && item.schedule,
              category: item.category,
              enterPermissionGranted: item.enterPermissionGranted,
            },
            confirmationData: item.confirmationData,
          }));

        this.resetActions();
        const {cancellationRule} = serviceRequest.modificationRules;
        if (cancellationRule && this.hasSreqPermission('SRCancel')) {
          this.addAction({
            routeName: this.$route.name,
            item: {
              id: 'sreq-cancel-request',
              title: 'cancel request',
              showConfirmation: true,
              confirmationType: 'warning',
              confirmationMessage: cancellationRule.confirmationData.questionText.toLowerCase(),
              confirmBtnText: cancellationRule.confirmationData.confirmBtnText.toLowerCase(),
              cancelBtnText: cancellationRule.confirmationData.cancelBtnText.toLowerCase(),
            },
          });
        }

        await Promise.all([
          (async () => {
            const assignees = this.hasSreqPermission('SRAU')
              ? await this.$sreqDataProvider.getList('assignees')
              : serviceRequest.metadata.assignee ? [serviceRequest.metadata.assignee] : [];

            this.assignees = assignees.map(item => ({key: item.id, value: this.toFullName(item)}));
          })(),

          (async () => {
            for (const categoryId of Object.keys(this.categories)) {
              if (categoryId === 'root') {
                continue;
              }

              await this.loadCategories(categoryId);
            }
          })(),
        ]);
      },

      initializeWatchers() {
        const {form} = this.$refs;

        this.$watch(
          () => this.formValues.currentState?.key,
          (statusId) => {
            const status = this.statuses.find(item => item.key === statusId);

            if (!status) {
              return;
            }

            form.finalForm.change('modificationRules', {
              ...this.formValues.modificationRules,
              ...status.modificationRules,
            });

            const registeredFields = form.finalForm.getRegisteredFields();

            Object.keys(status.modificationRules).forEach(field => {
              if (status.modificationRules[field] === 'NULL_ONLY') {
                const fieldName = registeredFields.find(name => name === field || name.startsWith(`${field}.`));
                const {dirty} = form.finalForm.getFieldState(fieldName);
                if (dirty && this.formValues[field] !== null) {
                  this.resetFields.push(field);
                }

                form.finalForm.change(field, null);
              }

              if (status.modificationRules[field] === 'READ_ONLY') {
                if (!isEqual(this.formValues[field], this.savedServiceRequest[field])) {
                  this.resetFields.push(field);
                }

                form.finalForm.change(field, this.savedServiceRequest[field]);
              }
            });
          },
        );

        this.$watch(
          () => this.formValues.locationPath,
          (locationPath, oldLocationPath) => {
            this.compareLocationPaths(locationPath, oldLocationPath, this.loadLocations, updatedLocationPath => {
              form.finalForm.change('locationPath', updatedLocationPath);
            });
          }
        );

        this.$watch(
          () => this.formValues.categoryPath,
          (categoryPath, oldCategoryPath) => {
            this.compareCategoryPaths(categoryPath, oldCategoryPath, this.loadCategories, updatedCategoryPath => {
              form.finalForm.change('categoryPath', updatedCategoryPath);
            });
          }
        );
      },

      async getRequestData(serviceRequest) {
        return {
          ...serviceRequest.data,
          ...serviceRequest.metadata,
          currentState: {
            key: serviceRequest.metadata.currentState.id,
            value: serviceRequest.metadata.currentState.name.toLowerCase(),
          },
          modificationRules: {
            assignee: serviceRequest.modificationRules.assignee,
            category: serviceRequest.modificationRules.category,
            schedule: serviceRequest.modificationRules.schedule,
            location: serviceRequest.modificationRules.location,
            priority: serviceRequest.modificationRules.priority,
            attachments: serviceRequest.modificationRules.attachments,
            notificationPreferences: serviceRequest.modificationRules.notificationPreferences,
            enterPermissionGranted: serviceRequest.modificationRules.enterPermissionGranted,
            alarmCode: serviceRequest.modificationRules.alarmCode,
          },
          cancellationRule: serviceRequest.modificationRules.cancellationRule,
          createdAt: serviceRequest.createdAt,
          areaType: serviceRequest.data.sharedLocation ? AREA_COMMON : AREA_UNIT,
          locationPath: this.buildLocationPath([], serviceRequest.data.location, serviceRequest.data.customLocation),
          categoryPath: this.buildCategoryPath([], serviceRequest.metadata.category, serviceRequest.metadata.customCategory),
        };
      },

      handleFastStatus(status) {
        const {form} = this.$refs;
        form.finalForm.change('currentState', status);
        form.finalForm.submit();
      },

      handleCancelRequest() {
        this.submitRequestChanges({
          ...this.serviceRequest,
          currentState: {
            key: this.serviceRequest.cancellationRule.state.id,
          },
        });
      },

      async handleFormSubmit(values) {
        const isStatusChanged = values.currentState.key !== this.savedServiceRequest.currentState.key;

        if (isStatusChanged) {
          if (this.resetFields.length > 0 && !await this.requestConfirmation({
            confirmationType: 'warning',
            confirmationMessage: this.resetFields.length === 1
              ? `for the status you selected, change in the ${this.resetFields[0]} field can’t be applied.`
              : `for the status you selected, changes in ${joinItems(this.resetFields)} fields can’t be applied.`,
          })) {
            return;
          }
        }

        const {confirmationData} = values.currentState;

        if (confirmationData && !await this.requestConfirmation({
          title: 'change status',
          confirmationType: 'success',
          confirmationMessage: confirmationData.questionText.toLowerCase(),
          confirmBtnText: confirmationData.confirmBtnText.toLowerCase(),
          cancelBtnText: confirmationData.cancelBtnText.toLowerCase(),
        })) {
          return;
        }

        this.submitRequestChanges(values);
      },

      submitRequestChanges(values) {
        this.loading = true;

        const isStatusChanged = values.currentState.key !== this.savedServiceRequest.currentState.key;

        return (isStatusChanged
          ? this.submitStatusChange(values)
          : this.submitDataChanges(values)
        )
          .then(serviceRequest => {
            this.endEdit();
            this.initializeServiceRequest(serviceRequest);
            this.notifySuccess('The request successfully changed');
            setTimeout(() => {
              this.$refs.form.finalForm.reset(this.serviceRequest);
            });

            this.resetTmpBuffer();
          })
          .catch(error => this.notifyError(error.message))
          .finally(() => {
            this.loading = false;
          });
      },

      submitStatusChange(values) {
        return this.$sreqDataProvider.updateStatus('serviceRequests', {
          id: this.id,
          data: {
            assignee: values.assignee,
            currentState: {
              id: values.currentState.key,
            },
            schedule: values.schedule,
          },
        });
      },

      submitDataChanges(values) {
        return this.uploadFiles()
          .then(() => this.$sreqDataProvider.update('serviceRequests', {
            id: this.id,
            data: {
              data: {
                location: {
                  id: this.getLastPredefinedLocation(values.locationPath),
                },
                customLocation: this.isCustomLocation(values.locationPath) ? values.customLocation : null,
                contactPreferences: values.modificationRules.notificationPreferences === 'NULL_ONLY'
                  ? null
                  : values.modificationRules.notificationPreferences === 'READ_ONLY'
                    ? values.contactPreferences
                    : {
                      preferredChannel: values.contactPreferences.preferredChannel,
                      email: this.isPreferredChannel(values, 'EMAIL') ? values.contactPreferences.email : null,
                      phone: this.isPreferredChannel(values, 'PHONE') ? values.contactPreferences.phone : null,
                    },
                notificationPreferences: values.modificationRules.notificationPreferences === 'NULL_ONLY'
                  ? null
                  : values.modificationRules.notificationPreferences === 'READ_ONLY'
                    ? values.notificationPreferences
                    : {
                      ...values.notificationPreferences,
                      email: values.notificationPreferences.sendEmail ? values.notificationPreferences.email : null,
                      phone: values.notificationPreferences.sendText ? values.notificationPreferences.phone : null,
                    },
                enterPermissionGranted: values.enterPermissionGranted,
                attachments: this.attachments.map(attach => ({id: attach.id})),
                alarmCode: values.alarmCode,
              },
              metadata: {
                assignee: values.assignee?.id ? values.assignee : null,
                category: (() => {
                  const id = this.getLastPredefinedCategory(values.categoryPath ?? []);
                  return id && {id}
                })(),
                customCategory: this.isCustomCategory(values.categoryPath ?? []) ? values.customCategory : null,
                priority: values.priority,
                schedule: values.schedule,
              },
            },
          })
        )
      },
    },

    created() {
      this.loading = true;

      Promise.all([
        this.$sreqDataProvider.getOne('serviceRequests', {id: this.id}).then(serviceRequest => {
          return this.initializeServiceRequest(serviceRequest);
        }),
        this.loadCategories(),
        this.$sreqDataProvider.getList('priorities').then(priorities => {
          this.priorities = priorities.map(item => ({key: item.id, value: item.name.toLowerCase(), level: item.level}));
        }),
        this.hasSreqPermission('TLV') && this.$sreqDataProvider.getTimeInOutLogs('serviceRequests', {id: this.id}).then(response => {
          this.timeInOutLogs = response;
        })
      ].filter(p => p))
        .then(() => this.initializeWatchers())
        .catch(error => this.notifyError(error.message))
        .finally(() => {
          this.loading = false;
        });
    },

    mounted() {
      EventBus.on('confirm-sreq-cancel-request', this.handleCancelRequest);
    },

    beforeUnmount() {
      EventBus.off('confirm-sreq-cancel-request', this.handleCancelRequest);
    },
    beforeRouteLeave(to, from, next) {
      next(vm => {
        if (to.meta.product !== 'sreq') {
          vm.setFilterValues({});
        }
      });
    },
  };
</script>

<style scoped>
  .fast-actions .btn-primary {
    font-size: 13px;
    @apply h-10 py-2 m-1 lowercase;
  }

  .grid {
    grid-gap: 1rem 2rem;
  }

  .grid .form-col {
    margin: 0;
  }

  .form-col.contact-channel {
    flex: 0 0 5rem;
    margin-top: 1.25rem;
  }

  .locations {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -1rem;
  }

  .locations > * {
    margin-bottom: 1rem;
  }

  .locations > .form-col {
    flex: none;
    margin-left: 0;
    margin-right: 0;
  }

  .locations > .arrow-wrapper {
    @apply flex items-center justify-center px-8;
    padding-top: 29px;
  }

  .main-section {
    border: 1px solid #CECECE;
    padding: 1.25rem;
    @apply mb-6 bg-gray-70;
  }

  .main-section.canceled {
    border: 1px solid #840000;
  }

  .main-section.completed {
    border: 1px solid #70BF88;
  }

  .main-section.canceled .status-text {
    color: #840000;
  }

  .section {
    @apply px-5;
  }

  .section + .section {
    @apply pt-4 mt-6;
    border-top: 1px solid #F0F0F0;
  }

  .heading {
    @apply font-bold text-black;
  }

  .section.request-details:deep(.alarm-code-input) {
    overflow-wrap: anywhere;
  }
</style>
