<template>
  <div :class="finalCss.tableClass">
    <div :class="finalCss.tableHeaderClass" ref="head">
      <div class="row" :class="finalCss.headRowClass">
        <div
          v-for="(column, index) in columns"
          :key="index"
          class="column"
          :class="[column.class, { sortable: column.sortable }]"
          v-on="
            column.sortable
              ? { click: () => $emit('sortClick', column.name) }
              : {}
          "
        >
          <!-- @slot List's column heading [column-head:columnName] -->
          <slot :name="`column-head:${column.name}`" :column="column">
            <template v-if="isComponentField(column.title)">
              <component :is="column.title"></component>
            </template>
            <template v-else>
              {{ column.title }}
            </template>
            <i
                v-if="column.sortable"
                :class="{
                  [finalCss.sortableIcon]: true,
                  [finalCss.ascendingIcon]:
                    column.name === sort.name && sort.dir === 'asc',
                  [finalCss.descendingIcon]:
                    column.name === sort.name && sort.dir === 'desc',
                }"
            />
          </slot>
        </div>
      </div>
    </div>
    <slot name="add" />
    <transition-group
      name="list"
      tag="div"
      :class="finalCss.tableBodyClass"
      ref="list"
    >
      <div
        v-for="(item, itemIndex) in items"
        :key="item[trackBy] || itemIndex"
        :class="finalCss.bodyRowClass"
        @click.stop="rowClick(item)"
      >
        <!-- @slot Custom row -->
        <slot name="row" :index="itemIndex" :item="item" />
        <template v-if="!hasCustomRow">
          <div
            v-for="column in columns"
            :key="column.name"
            class="column"
            :class="column.class"
          >
            <!-- @slot List's column [column:columnName] -->
            <slot
              :name="`column:${column.name}`"
              :index="itemIndex"
              :item="item"
              :value="rawColumnValue(item, column.name)"
            >
              {{ visibleColumnValue(item, column.name) }}
            </slot>
          </div>
        </template>
      </div>
    </transition-group>
    <div
      v-if="items.length === 0"
      class="flex items-center justify-center bg-blue-100 border border-blue-300 text-blue-700 py-6"
    >
      <span class="flex items-center justify-center w-4 h-4 border-2 border-blue-700 rounded-full text-xs font-frank font-600 mr-2">!</span>
      <span class="font-frank font-medium text-sm">{{ emptyText }}</span>
    </div>
  </div>
</template>

<script>
import { get } from "lodash-es";

const css = {
  tableClass: "table table--striped table--highlighted",
  tableBodyClass: "body",
  tableHeaderClass: "head sticky top-0 z-10",
  headRowClass: "bg-white border-b border-black",
  bodyRowClass: "row cursor-pointer",
  ascendingIcon: "blue chevron up",
  descendingIcon: "blue chevron down",
  sortableIcon: "sort-icon grey sort icon",
  ascendingClass: "sorted-asc",
  descendingClass: "sorted-desc",
};

export default {
  name: "List",
  props: {
    /**
     * Custom CSS styles
     */
    css: {
      type: Object,
      default() {
        return css;
      },
    },

    /**
     * List columns
     */
    columns: {
      type: Array,
      required: true,
    },

    /**
     * List data
     */
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },

    /**
     * Indicates if items are filtered
     */
    hasFilters: {
      type: Boolean,
      default: false,
    },

    /**
     * Resource name used in 'empty' text
     */
    resourceLabel: {
      type: String,
      default: "items",
    },

    /**
     * Column name that can uniquely identify each row
     */
    trackBy: {
      type: String,
      default: "id",
    },

    /**
     * Sort data object
     */
    sort: {
      type: Object,
      default: () => ({
        name: "",
        dir: "asc",
      }),
    },
  },
  emits: [
    'sortClick',
    'rowClick',
  ],
  computed: {
    emptyText() {
      return this.hasFilters ? "no results" : `no ${this.resourceLabel} yet`;
    },
    hasCustomRow() {
      return !!this.$slots["row"];
    },
    finalCss() {
      return { ...css, ...this.css };
    },
  },

  methods: {
    rawColumnValue: get,
    visibleColumnValue(item, column) {
      const v = get(item, column);
      return v == null || v === "" || (Array.isArray(v) && v.length === 0) ? "-" : v;
    },
    isComponentField(fieldName) {
      return fieldName instanceof Object;
    },
    rowClick(item){
      /**
       * Emitted on row click
       */
      this.$emit('rowClick', item);
    }
  },
};
</script>

<style scoped>
.table {
  @apply text-black font-normal w-full;
}

.row {
  @apply flex;

  .list-enter-from,
  .list-leave-to {
    @apply bg-blue-100;
  }

  .list-enter-active,
  .list-leave-active {
    @apply transition-all duration-500;
  }
}

.head > .row .column {
  @apply font-frank font-medium text-xs p-2;

  &.sortable:hover {
    color: var(--highlightColorBase);
    cursor: pointer;
  }

  &:first-child {
    @apply pl-4;
  }

  &:last-child {
    @apply pr-6;
  }
}

.table--striped > .body > .row:nth-child(even) {
  background-color: #f4f4f4;
}

.table--highlighted > .body > .row:hover {
  @apply bg-blue-100;
}

.body > .row {
  @apply items-center bg-white;

  &.selected {
    @apply bg-blue-300;
  }

  & > .column {
    @apply text-sm border-none px-2 py-4;

    &:first-child {
      @apply pl-4;
    }

    &:last-child {
      @apply pr-6;
    }
  }
}

i.sort::after {
  display: flex;
  content: url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' width='10' height='12' viewBox='0 0 10 12' fill='none'><path d='M10 5L5 -4.37114e-07L0 5' fill='rgb(199 199 198)'/><path d='M0 7L5 12L10 7' fill='rgb(199 199 198)'/></svg>");
}

i.chevron.up::after {
  display: flex;
  content: url("data:image/svg+xml;charset=UTF-8, <svg width='10' height='12' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M10 5L5 -4.37114e-07L0 5' fill='rgb(33 133 208)'/></svg>");
}

i.chevron.down::after {
  display: flex;
  content: url("data:image/svg+xml;charset=UTF-8, <svg width='10' height='12' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 0L5 5L10 0' fill='rgb(33 133 208)'/></svg>");
}

.sort-icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.3rem;
}
</style>
