<template>
<div class="main-wrapper" :class="{disabled}">
  <span v-if="!editMode" class="action-wrapper" @click="tryToEmit('startEdit')" title="edit">
    <icon name="edit" class="action edit" :class="disabled ? 'text-graphite-700' : 'text-active-500'"/>
  </span>
  <span v-if="editMode" class="action-wrapper" @click="tryToEmit('cancel')" title="cancel">
    <icon name="close" class="action" :class="disabled ? 'text-graphite-700' : 'text-graphite-800'"/>
  </span>
  <span v-if="editMode" class="action-wrapper" @click="tryToEmit('save')" title="save">
    <icon name="checkmark" class="action" :class="disabled ? 'text-graphite-700' : 'text-active-500'"/>
  </span>
</div>
</template>

<script>
import Icon from "@/components/ui/Icon";

export default {
  name: "EditIconsBlock",

  components: {Icon},

  props: {
    editMode: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['startEdit', 'cancel', 'save'],

  methods: {
    tryToEmit(eventName) {
      if (!this.disabled) {
        this.$emit(eventName);
      }
    },
  },
}
</script>

<style scoped>
.main-wrapper {
  @apply flex;

  &:not(.disabled) {
    .action-wrapper:hover {
      @apply bg-active-25;
    }

    .action {
      @apply cursor-pointer;
    }
  }
}

.action-wrapper {
  @apply flex items-center justify-center w-6 h-6 rounded;
}

.action {
  @apply w-3 h-3;
}

.action.edit {
  @apply w-auto h-auto;
}
</style>
