export const SUBMITTER_RESIDENT = 'SUBMITTER_RESIDENT';
export const SUBMITTER_MANAGER = 'SUBMITTER_MANAGER';

export const AREA_UNIT = 'AREA_UNIT';
export const AREA_COMMON = 'AREA_COMMON';

export const STATUS_CANCELED = 'CANCELED';
export const STATUS_COMPLETED = 'COMPLETED';
export const STATUS_RESIDENT_VALIDATION = 'RESIDENT_VALIDATION';

export const LOCATION_CUSTOM = 'LOCATION_CUSTOM';
export const CATEGORY_CUSTOM = 'CATEGORY_CUSTOM';

export const STATUS_SCHEMA_BASIC = 'BASIC';
export const STATUS_SCHEMA_ADVANCED = 'ADVANCED';
