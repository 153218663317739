<template>
  <div>
    <List :columns="columns" :items="items" class="list">
      <template v-slot:column:timeIn="{value}">
        <div class="text-sm font-semibold mb-2">
          <CommunityDateTimeDisplay :input="value.dateTime" format="MMMM D, YYYY, hh:mm a"/>
        </div>
        <div class="text-xs">
          <div class="mb-1">
            <span class="font-semibold">Author:</span> {{ `${value.author.firstName} ${value.author.lastName}` }}
          </div>
          <div class="capitalize mb-1">
            {{ value.author.role.name }}
          </div>
          <div>
            <CommunityDateTimeDisplay :input="value.createdAt" format="MMMM D, YYYY, hh:mm a"/>
          </div>
        </div>
      </template>

      <template v-slot:column:timeOut="{item, value}">
        <template v-if="value">
          <div class="text-sm font-semibold mb-2">
            <CommunityDateTimeDisplay :input="value.dateTime" format="MMMM D, YYYY, hh:mm a"/>
          </div>
          <div class="text-xs">
            <div class="mb-1">
              <span class="font-semibold">Author:</span> {{ `${value.author.firstName} ${value.author.lastName}` }}
            </div>
            <div class="capitalize mb-1">
              {{ value.author.role.name }}
            </div>
            <div>
              <CommunityDateTimeDisplay :input="value.createdAt" format="MMMM D, YYYY, hh:mm a"/>
            </div>
          </div>
        </template>

        <template v-else-if="hasSreqPermission('TLM') && item.editable">
          <DateTimeOrDurationForm :date-in="item.timeIn.dateTime" @submit="(dateOut) => handleDateOutSubmit(item.id, dateOut)"/>
        </template>
      </template>

      <template v-slot:column:persons="{value: persons}">
        <Tooltip v-for="person in persons" :key="person.id">
          <template v-slot:label>
            <Tag :text="`${person.firstName} ${person.lastName}`" class="mb-1"/>
          </template>
          <template v-slot:default>
            <div class="text-2sm font-normal">
              <div class="mb-1">{{ person.role.name }}</div>
              <div class="mb-1">{{ person.email }}</div>
              <div class="mb-1">{{ person.phone }}</div>
            </div>
          </template>
        </Tooltip>
      </template>
    </List>

    <loader :loading="loading" backdrop/>
  </div>
</template>

<script>
import List from "@/components/list/List";
import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";
import Tag from "@/components/ui/Tag";
import CommunityDateTimeDisplay from "@/components/ui/CommunityDateTimeDisplay";
import Tooltip from "@/components/ui/Tooltip";
import DateTimeOrDurationForm from "@/components/sreq/DateTimeOrDurationForm";
import Loader from "@/components/ui/Loader";
import NotifyMixin from "@/mixins/NotifyMixin";
import SreqMixin from "@/mixins/SreqMixin";

const columns = [
  {
    name: 'timeIn',
    title: 'time-in',
    class: 'w-4/12',
  },
  {
    name: 'timeOut',
    title: 'time-out',
    class: 'w-4/12',
  },
  {
    name: 'persons',
    title: 'committed by',
    class: 'w-4/12',
  },
];

const _prepareItem = (item) => ({
  id: item.id,
  timeIn: {
    dateTime: item.inDate,
    author: item.inCreateUser,
    createdAt: item.inCreatedAt,
  },
  timeOut: !item.outDate ? null : {
    dateTime: item.outDate,
    author: item.outCreateUser,
    createdAt: item.outCreatedAt,
  },
  persons: item.users,
  editable: item.editable,
});

export default {
  components: {Loader, DateTimeOrDurationForm, Tooltip, CommunityDateTimeDisplay, Tag, List},

  mixins: [CommunityTimezoneMixin, NotifyMixin, SreqMixin],

  props: {
    logItems: {
      type: Array,
      required: true,
    },

    columns: {
      type: Array,
      default: () => columns,
    },
  },

  data() {
    return {
      items: [],
      loading: false,
    };
  },

  watch: {
    logItems: {
      handler(items) {
        this.items = items.map(_prepareItem);
      },
      immediate: true,
    },
  },

  methods: {
    handleDateOutSubmit(id, outDate) {
      this.loading = true;
      this.$sreqDataProvider.update('timeInOutLogs', {
        id,
        data: {
          outDate
        },
      }).then(updatedLogItem => {
        const index = this.items.findIndex(item => item.id === updatedLogItem.id);
        this.items[index] = _prepareItem(updatedLogItem);
      }).catch(error => {
        this.notifyError(error.message);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
}
</script>

<style scoped>
.list:deep(.row) {
  @apply items-start;
}
</style>
