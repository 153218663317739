<template>
  <FinalField :name="name" :validate="validate">
    <template v-slot="props">
      <div :class="{inline: inline, 'form-col': !inline}">
        <label v-if="label">{{label}}</label>
        <label :for="name" class="checkbox-label" :class="{'cursor-pointer': editMode, disabled}">
          <input
            v-if="editMode"
            :id="name"
            :data-test="name"
            :disabled="disabled"
            type="checkbox"
            class="cursor-pointer"
            @blur="props.events.blur()"
            @focus="props.events.focus()"
            @change="props.change(props.value)"
            v-model="props.value"
          />
          <icon
            v-if="!editMode"
            class="inline"
            :name="props.value ? 'ok' : 'cancel'"
          />
          {{value}}
          <tooltip
              v-if="disabled && disableReason"
              icon="exclamationCircle"
              :text="disableReason"
              :arrow="false"
              class="text-left w-3 h-3 ml-1"
          />
        </label>
        <span class="form-hint form-error" v-if="props.meta.error && props.meta.touched">
          {{ props.meta.error }}
        </span>
      </div>
    </template>
  </FinalField>
</template>

<script>
  import { FinalField } from 'vue-final-form';
  import Icon from '@/components/ui/Icon';
  import Tooltip from "@/components/ui/Tooltip";

  export default {
    name: 'CheckboxInput',
    components: {
      Tooltip,
      FinalField,
      Icon,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
      },
      validate: {
        type: [Function, Array],
        required: false,
      },
      value: {
        type: String
      },
      inline: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false
      },
      disableReason: {
        type: String,
        required: false,
      },
      editMode: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style scoped>
  .form-col label.checkbox-label {
    font: inherit;
  }

  .disabled {
    @apply text-gray-500;
  }

  .form-col:deep(.tooltip-wrapper) {
    vertical-align: -4px;
  }
</style>
