<template>
  <FinalForm
      ref="form"
      :submit="handleSubmit"
      :initial-values="initialValues"
      @change.self="handleFormChange"
  >
    <template v-slot="props">
      <form ref="formElement" @submit="props.handleSubmit" class="flex flex-col">
        <div class="flex justify-start font-frank font-medium text-xs mb-2">
          <div
              class="cursor-pointer mr-2"
              :class="mode === 'datepicker' ? 'text-active-500' : 'text-graphite-800'"
              @click="mode = 'datepicker'"
          >
            date picker
          </div>
          <div
              class="cursor-pointer"
              :class="mode === 'duration' ? 'text-active-500' : 'text-graphite-800'"
              @click="mode = 'duration'"
          >
            duration
          </div>
        </div>
        
        <div class="fields mb-3">
          <DateTimeField
              ref="dateTime"
              v-if="mode === 'datepicker'"
              name="dateTime"
              :validate="[
                required,
                minDateTime(dateIn, formatDateTime),
                maxDateTime(new Date().toISOString(), formatDateTime)
              ]"
              :min-date="dateIn"
              :max-date="(new Date()).toISOString()"
          />
          <DurationField
              ref="duration"
              v-if="mode === 'duration'"
              name="duration"
              :validate="[
                  required,
                  maxDuration(getMaxDuration()),
              ]"
          />
        </div>
        
        <div class="actions-wrapper">
          <span class="action-wrapper hover:bg-active-25" @click="resetForm" title="cancel">
            <icon name="close" class="action text-graphite-800"/>
          </span>
          <span class="action-wrapper" @click="submitForm" title="save" :class="!props.invalid && 'hover:bg-active-25'">
            <icon name="checkmark" class="action" :class="props.invalid ? 'text-graphite-700' : 'text-active-500'"/>
          </span>
        </div>
      </form>
    </template>
  </FinalForm>
</template>

<script>
import {FinalForm} from "vue-final-form";
import DateTimeField from "@/components/form/DateTimeField";
import ValidatorMixin from "@/components/form/ValidatorMixin";
import DurationField from "@/components/form/DurationField";
import Icon from "@/components/ui/Icon";
import moment from "moment-timezone";
import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";

export default {
  components: {Icon, DurationField, DateTimeField, FinalForm},

  mixins: [ValidatorMixin, CommunityTimezoneMixin],

  props: {
    dateIn: {
      type: String,
      required: true,
    },
  },

  emits: ['submit'],

  data() {
    return {
      initialValues: {
        dateTime: null,
        duration: null,
      },
      formValues: null,

      mode: 'datepicker',
    };
  },

  methods: {
    handleFormChange(state) {
      this.formValues = state.values;
    },

    resetForm() {
      this.$refs.form.finalForm.restart();
      if (this.mode === 'datepicker') {
        this.$refs.dateTime.clear();
      } else {
        this.$refs.duration.clear();
      }
    },

    submitForm() {
      this.$refs.formElement.requestSubmit();
    },

    handleSubmit(values) {
      if (this.mode === 'datepicker') {
        this.$emit('submit', values.dateTime);
      } else {
        this.$emit('submit', moment(this.dateIn).add(values.duration, 'minutes').toISOString());
      }
    },

    getMaxDuration() {
      return Math.floor((new Date().getTime() - new Date(this.dateIn).getTime()) / 1000 / 60);
    },
  },

  created() {
    this.formValues = this.initialValues;
  },
}
</script>

<style scoped>
.fields:deep(.form-col) {
  @apply mx-0;
}

.actions-wrapper {
  @apply flex justify-end;
}
.action-wrapper {
  @apply flex items-center justify-center w-6 h-6 rounded;
}
.action {
  @apply w-3 h-3 cursor-pointer;
}
</style>
