<script>
  import {required} from "@/components/form/validators";
  import {AREA_COMMON, AREA_UNIT, LOCATION_CUSTOM} from "./constants";

  export default {
    name: 'LocationMixin',

    data() {
      return {
        areaTypeProxy: AREA_UNIT,
        residentProfileIdProxy: null,

        /**
         * Structure is:
         * {
         *   '<areaType>[<residentProfileId>]': {
         *     <parentId>: [
         *       {id: ..., name: '...'},
         *       ...
         *     ],
         *   },
         *   ...
         * }
         */
        locations: {
          [AREA_UNIT]: {root: []},
        },
      };
    },

    computed: {
      currentContextStamp() {
        return this.areaTypeProxy + (this.residentProfileIdProxy || '');
      },
    },

    methods: {
      loadLocationsData(areaType, parentId, residentProfileId) {
        const shared = areaType === AREA_COMMON;
        const contextStamp = areaType + (residentProfileId || '');

        return this.$sreqDataProvider.getList('locations', {shared, parentId, residentProfileId})
          .then(locations => {
            this.locations[contextStamp] = this.collectLocations(locations, {...this.locations[contextStamp]}, parentId);
          });
      },

      collectLocations(locations, acc = {}, parentId = 'root') {
        acc[parentId] = acc[parentId] ?? [];

        if (locations && locations.length) {
          acc[parentId] = locations.map(this.mapItemToOption);
          locations.forEach(location => {
            this.collectLocations(location.locations, acc, location.id);
          });
        }

        return acc;
      },

      insertResidentLocations(locations) {
        this.locations[this.currentContextStamp] = this.collectLocations(locations);
      },

      compareLocationPaths(locationPath, oldLocationPath, loadLocations, updateLocationPath) {
        const index = oldLocationPath.length
          ? locationPath.findIndex((item, i) => item !== oldLocationPath[i])
          : locationPath.length;

        if (index < 0) {
          return;
        }

        const updatedLocationPath = locationPath.slice(0, index + 1);

        const lastId = updatedLocationPath.slice(-1)[0];

        if (lastId && lastId !== LOCATION_CUSTOM && (!this.locations[this.currentContextStamp]?.[lastId] || !this.locations[this.currentContextStamp]?.[lastId]?.length)) {
          loadLocations(lastId);
        }

        if (index >= locationPath.length - 1) {
          return;
        }

        updateLocationPath(updatedLocationPath);
      },

      locationDropdownOptions(locationPath, areaType, index) {
        if (index === 0) {
          return this.locations[this.currentContextStamp]?.root || [];
        }

        if (!locationPath) {
          return [];
        }

        const parentId = locationPath[index - 1];

        const childrenLocations = this.locations[this.currentContextStamp][parentId];

        if (!childrenLocations || childrenLocations.length === 0) {
          return [];
        }

        if ((areaType === AREA_UNIT && index >= 2) || (areaType === AREA_COMMON && index >= 1)) {
          return [
            ...childrenLocations,
            {
              key: LOCATION_CUSTOM,
              value: 'other',
            },
          ];
        }

        return childrenLocations;
      },

      locationValidation(areaType, index) {
        if ((areaType === AREA_UNIT && index <= 1) || (areaType === AREA_COMMON && index === 0)) {
          return required;
        }

        return null;
      },

      isCustomLocation(locationPath) {
        return locationPath.filter(id => id).slice(-1)[0] === LOCATION_CUSTOM;
      },

      getLastPredefinedLocation(locationPath) {
        return locationPath.filter(id => id && id !== LOCATION_CUSTOM).slice(-1)[0];
      },

      locationToString(areaType, locationPath, customLocation) {
        const locationNames = locationPath.map((id, index) => {
          if (id === LOCATION_CUSTOM) {
            return `${customLocation} (other)`;
          }

          const parentId = locationPath[index - 1] ?? 'root';
          return this.locations[this.currentContextStamp]?.[parentId].find(loc => loc.key === id)?.value;
        })
          .filter(s => s);

        if (areaType === AREA_UNIT) {
          const [buildingName, unitName, ...otherLocations] = locationNames;
          const buildingAndUnitName = `${buildingName}:${unitName}`;
          return otherLocations.length
            ? `${buildingAndUnitName} – ${otherLocations.join(', ')}`
            : buildingAndUnitName;
        }

        return locationNames.join(', ');
      },
    },
  };
</script>
