<template>
  <FinalField :name="name" :validate="validate">
    <template v-slot="props">
      <div class="form-col">
        <label class="radio-label" :class="{'cursor-pointer': editMode}">
          <input
            v-if="editMode"
            type="radio"
            :name="props.name"
            :checked="props.value === value"
            :value="value"
            :disabled="disabled"
            class="cursor-pointer"
            @blur="props.events.blur()"
            @focus="props.events.focus()"
            @change="props.change(value)"
          />
          <icon
            v-if="!editMode"
            class="inline"
            :name="props.value ? 'ok' : 'cancel'"
          />
          {{label}}
        </label>
      </div>
    </template>
  </FinalField>
</template>

<script>
  import { FinalField } from 'vue-final-form';
  import Icon from '@/components/ui/Icon';

  export default {
    name: 'RadioInput',
    components: {
      FinalField,
      Icon,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
      },
      validate: {
        type: [Function, Array],
        required: false,
      },
      value: {
        type: String
      },
      disabled: {
        type: Boolean,
        default: false
      },
      editMode: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>

<style scoped>
  .form-col label.radio-label {
    font: inherit;
    padding-left: 1px;
  }
</style>
