// Join string items with comma, but the last separator will be 'and'
const joinItems = function (items) {
    if (items.length === 1) {
        return items[0];
    }

    if (items.length === 2) {
        return items.join(' and ');
    }

    if (items.length > 2) {
        return items.slice(0, -1).join(', ') + ', and ' + items.slice(-1);
    }

    return '';
};

// Get a random array element
const randomElement = function (items) {
    return items[Math.floor(Math.random() * items.length)];
};

export { joinItems, randomElement };
