<script>
  import {sortBy} from 'lodash-es';
  import {CATEGORY_CUSTOM} from "./constants";

  export default {
    name: 'CategoryMixin',

    data() {
      return {
        categories: {
          root: [],
        },
      };
    },

    methods: {
      loadCategoriesData(parentId) {
        return this.$sreqDataProvider.getList('categories', {parentId})
          .then(categories => {
            this.categories = this.collectCategories(categories, {...this.categories}, parentId);
          });
      },

      collectCategories(categories, acc = {}, parentId = 'root') {
        acc[parentId] = acc[parentId] ?? [];

        if (categories && categories.length) {
          acc[parentId] = categories.map(this.mapItemToOption);
        }

        return acc;
      },

      collectCategoryTree(category, acc) {
        if (!category) {
          return acc;
        }

        const parentId = category.parent?.id || 'root';

        acc[parentId] = acc[parentId] ?? [];

        if (!acc[parentId].find(item => item.key === category.id)) {
          acc[parentId].push(this.mapItemToOption(category));
        }

        acc[category.id] ??= [];

        return this.collectCategoryTree(category.parent, acc);
      },

      compareCategoryPaths(categoryPath, oldCategoryPath, loadCategories, updateCategoryPath) {
        const index = oldCategoryPath.length
            ? categoryPath.findIndex((item, i) => item !== oldCategoryPath[i])
            : categoryPath.length;

        if (index < 0) {
          return;
        }

        const updatedCategoryPath = categoryPath.slice(0, index + 1);

        const lastId = updatedCategoryPath.slice(-1)[0];

        if (lastId && lastId !== CATEGORY_CUSTOM && (!this.categories[lastId] || !this.categories[lastId]?.length)) {
          loadCategories(lastId);
        }

        if (index >= categoryPath.length - 1) {
          return;
        }

        updateCategoryPath(updatedCategoryPath);
      },

      categoryDropdownOptions(categoryPath, index) {
        if (!categoryPath) {
          return [];
        }

        const parentId = (index === 0)
          ? 'root'
          : categoryPath[index - 1];

        const childrenCategories = sortBy(this.categories[parentId], 'value');

        if (!childrenCategories || childrenCategories.length === 0) {
          return [];
        }

        return [
          ...childrenCategories,
          {
            key: CATEGORY_CUSTOM,
            value: 'other',
          },
        ];
      },

      categoryDropdownLabel(index) {
        switch (index) {
          case 0:
            return 'category';

          case 1:
            return 'subcategory';
        }

        return `category (level ${index + 1})`;
      },

      categoryOtherLabel(categoryPath) {
        if (categoryPath.length > 1) {
          return 'other subcategory*';
        }

        return 'other category*';
      },

      categorySelectClasses(categoryPath, editCategory, index) {
        if (!editCategory && index > 0 && (!categoryPath[index] || categoryPath[index] === CATEGORY_CUSTOM)) {
          return {hidden: true};
        }

        if (this.categoryDropdownOptions(categoryPath, index).length === 0) {
          return {hidden: true};
        }
      },

      categoryValidation(index, validator) {
        if (index === 0) {
          return validator;
        }

        return null;
      },

      isCustomCategory(categoryPath) {
        return categoryPath.filter(id => id).slice(-1)[0] === CATEGORY_CUSTOM;
      },

      getLastPredefinedCategory(categoryPath) {
        return categoryPath.filter(id => id && id !== CATEGORY_CUSTOM).slice(-1)[0];
      },
    },
  };
</script>