<template>
  <span class="priority-indicator" :class="priorityClass"></span>
</template>

<script>
  export default {
    props: {
      value: {
        type: Number,
        required: true,
      },
    },

    computed: {
      priorityClass() {
        switch (this.value) {
          case 1:
            return 'red';
          case 2:
            return 'orange';
          case 3:
            return 'yellow';
          case 4:
            return 'green';
        }
        return 'gray';
      },
    }
  };
</script>

<style scoped>
  .priority-indicator {
    width: .4375rem;
    height: .4375rem;
    display: inline-block;
    border-radius: 100%;
  }

  .priority-indicator.red {
    background: #F4CDCD;
  }

  .priority-indicator.orange {
    background: #F4DBC3;
  }

  .priority-indicator.yellow {
    background: #F5EDC1;
  }

  .priority-indicator.green {
    background: #BDE5CB;
  }
</style>
