<template>
  <FinalField :name="name" :validate="validate">
    <template v-slot="props">
      <div class="form-col">
        <label v-if="label">{{label}}</label>

        <div v-if="!editMode">{{textValue(props.value)}}</div>

        <DatePicker
          v-if="editMode"
          :value="props.value"
          @change="(v) => props.change(formatter(prepareDateInTimezone(v)))"
          @clear="() => props.change(null)"
          @updateMonthYear="val => $emit('updateMonthYear', val)"
          @close="$emit('close')"
          :format="format"
          :autoApply="true"
          :class="{error: props.meta.error && props.meta.touched}"
          :clearable="clear"
          v-bind="{
            name,
            disabled,
            disabledDates,
            minDate,
            maxDate,
            placeholder,
            allowedDates,
            monthYear,
            monthNameFormat,
            inCommunityTimezone,
          }"
        />

        <FieldError v-if="!suppressError" :name="name" />
      </div>
    </template>
  </FinalField>
</template>

<script>
  import {FinalField} from 'vue-final-form';
  import moment from 'moment-timezone';
  import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";
  import DatePicker from '@/components/ui/datepicker/DatePicker';
  import FieldError from "@/components/form/FieldError";

  export default {
    name: 'DateInput',
    components: {
      DatePicker,
      FinalField,
      FieldError,
    },
    mixins: [CommunityTimezoneMixin],
    props: {
      name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
      },
      format: {
        type: String,
        default: 'MM/DD/YYYY',
      },
      textFormat: {
        type: String,
        default: 'MM/DD/YYYY',
      },
      validate: {
        type: [Function, Array],
        required: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      editMode: {
        type: Boolean,
        default: true,
      },
      inCommunityTimezone: {
        type: Boolean,
        default: true
      },
      /**
       * Disable specific dates
       */
      disabledDates: {
        type: [Function, Array], //String[] | (date: Date) => boolean, //return true for a disabled date
      },
      /**
       * All dates before the given date will be disabled
       */
      minDate: {
        type: String,
        default: null,
      },
      /**
       * All dates after the given date will be disabled
       */
      maxDate: {
        type: String,
        default: null,
      },
      placeholder: {
        type: String,
        default: 'select a date',
      },
      clear: {
        type: Boolean,
        default: true
      },
      allowedDates: {
        type: Array,
        default: () => [],
      },
      /**
       * Formats value before saving to the form state
       */
      formatter: {
        type: Function,
        default: (value) => value,
      },
      monthYear: {
        type: Function,
      },
      monthNameFormat: {
        type: String,
      },
      suppressError: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    emits: ['updateMonthYear', 'close'],
    methods: {
      prepareDateInTimezone(date) {
        if (!date) {
          return null;
        }

        return this.parseDateTime(date, this.inCommunityTimezone).toDate();
      },
      textValue(value) {
        if (!value) {
          return '-';
        }

        const m = this.inCommunityTimezone ? this.parseDateTime(value) : moment(value);

        if (!m.isValid()) {
          return '-';
        }

        return m.format(this.textFormat);
      },
    },
  };
</script>
